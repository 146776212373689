class MobileMenu {
    constructor() {
        this.mobileMenuBtn = document.querySelector('.mobile-menu-btn')
        this.navBar = document.querySelector('.nav-bar')
        this.tnrNavBarContainer = document.querySelector('.tnr-nav-bar-container')

        this.handleMenuBtnClick = this.handleMenuBtnClick.bind(this)
        this.collapseMobileNavMenu = this.collapseMobileNavMenu.bind(this)
        this.mobileMenuBtn?.addEventListener('click', this.handleMenuBtnClick)
    }

    handleMenuBtnClick = (evt) => {
        this.navBar?.classList.toggle('nav-bar--opened')
        this.mobileMenuBtn?.classList.toggle('mobile-menu-btn--opened')
        this.tnrNavBarContainer?.classList.toggle('tnr-nav-bar-container--opened')
    }

    collapseMobileNavMenu = () => {
        this.navBar?.classList.remove('nav-bar--opened')
        this.mobileMenuBtn?.classList.remove('mobile-menu-btn--opened')
        this.tnrNavBarContainer?.classList.remove('tnr-nav-bar-container--opened')
    }
}

if (document.querySelector('.nav-bar')) {
    const mobileMenu = new MobileMenu()

    window.onresize = window.onload = function () {
        if (window.innerWidth >= 600) {
            mobileMenu.collapseMobileNavMenu()
        }
    }
}
